import React from 'react';
import {
  HashRouter, Switch, Route, Redirect,
} from 'react-router-dom';
import MomentLocalizer from 'react-widgets-moment';
import moment from 'moment';

import ContextProvider from 'js/react/components/ContextProvider';
import MainMenu from 'js/react/system/MainMenu';
import routes from 'js/system/routes';
import cancan from 'js/system/cancan';
import Localization from 'react-widgets/esm/Localization';
import { NEXT_UI } from 'constants/permissions';

const localizer = new MomentLocalizer(moment);

function RouteWithSubRoutes(route) {
  const canAccess = (rt) => {
    if (typeof rt.can === 'undefined') {
      return true;
    }

    return cancan.can('read', route.can);
  };

  // Redirect to the new UI if the user has the permission
  if (route.nextUrl && cancan.can('read', NEXT_UI)) {
    window.location.replace(`v2${route.nextUrl}`);
  }

  return (
    <Route
      path={route.path}
      render={(props) => (
        canAccess(route) ? (
          // pass the sub-routes down to keep nesting
          // eslint-disable-next-line react/jsx-props-no-spreading
          <route.component {...props} routes={route.routes} />
        ) : (
          <Redirect to={{ pathname: '/' }} />
        )
      )}
    />
  );
}

const App = ({ username }) => {
  return (
    <Localization
      date={localizer}
    >
      <HashRouter>
        <div id="app_header">
          <MainMenu username={username} />
        </div>
        <div id="app_view" className="col overflow-y-auto min-height-1">
          <ContextProvider>
            <Switch>
              {routes.map((route, i) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <RouteWithSubRoutes key={i} {...route} />
              ))}
            </Switch>
          </ContextProvider>
        </div>
      </HashRouter>
    </Localization>
  );
};

export default App;
